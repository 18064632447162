import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowRight, faCircleNodes, faGroupArrowsRotate, faHandHoldingHeart, faLockOpen, faPersonChalkboard, faShieldHalved, faHeart as faHeartSolid, faComment as faCommentSolid, faChevronRight } from '@fortawesome/free-solid-svg-icons'
import { faHeart, faComment } from '@fortawesome/free-regular-svg-icons'

import Image from '../Image'

import styles from './StepsCircle.module.scss'

import logo from '../../public/breakeven.svg'
import { IconProp } from '@fortawesome/fontawesome-svg-core'
import { CSSProperties, ReactNode, useState } from 'react'
import { set } from 'date-fns'
import Link from 'next/link'
import { AnimatePresence, motion } from 'framer-motion'

export type CircleStepInfo = {
  title: string
  value: string
  link?: string
  icon: IconProp
  stepIcon?: IconProp
  top?: string | number
  left?: string | number
  bottom?: string | number
  right?: string | number
}

const stepsData: Array<CircleStepInfo> = [{
  title: 'Outreach and Engagement',
  value: 'Raise awareness with peofessionals, communities and individuals and promote open conversations about gambling and gambling related harms.',
  link: '/professionals/education-and-training',
  icon: faComment,
  stepIcon: faCommentSolid,
  top: 0,
  left: '40%'
}, {
  title: 'Education',
  value: 'Deliver a range of training and workshop options to increase awareness of gambling harms, support options available and reduce stigmas attached to those gambling and affected others.',
  link: '/professionals/education-and-training',
  icon: faPersonChalkboard,
  top: '12.5%',
  right: '12.5%'
}, {
  title: 'Mobilisation',
  value: 'Work with the National Gambling Support Network, NHS Services and local systems to promote a collaborative, researched and effective approach to signposting, referrals and treatment.',
  link: '/other-organisations',
  icon: faGroupArrowsRotate,
  top: '40%',
  right: 0
}, {
  title: 'Accessibility',
  value: 'Remove barriers to treatment by engaging closely with communities to ensure that treatment is always accessible, non-judgemental and safe for all.',
  link: '/other-organisations',
  icon: faLockOpen,
  bottom: '12.5%',
  right: '12.5%'
}, {
  title: 'Treatment',
  value: 'Provide client focused treatment that is flexible to individual needs and supports anyone affected by gambling harms.',
  link: '/gambling-harms-treatment',
  icon: faHandHoldingHeart,
  bottom: 0,
  left: '40%'
}, {
  title: 'Pathways',
  value: 'Continue to create a network of vetted partners and referral pathways to support individuals with a variety of needs and concerns.',
  link: '/other-organisations',
  icon: faCircleNodes,
  bottom: '12.5%',
  left: '12.5%'
}, {
  title: 'Aftercare',
  value: 'Use a variety of initiatives including peer support, follow ups, educational programmes and more to support ongoing recovery when treatment ends for as long as clients need.',
  link: '/aftercare',
  icon: faHeart,
  stepIcon: faHeartSolid,
  top: '40%',
  left: 0
}, {
  title: 'Protection',
  value: 'Encourage partners to sign the Workspace Charter - providing tools, resources and committing them to protect themselves and those they work with from gambling harms going forward.',
  link: '/professionals/education-and-training',
  icon: faShieldHalved,
  top: '12.5%',
  left: '12.5%'
}]

export default function StepsCircle({
  backgroundColour = '#ffffff',
  addContainer,
  addPadding
}: {
  backgroundColour?: string
  addContainer?: boolean
  addPadding?: boolean
}) {
  const [hoveredCircle, setHoveredCircle] = useState<number>(0)
  const [hoveredStep, setHoveredStep] = useState<number>(0)

  return (
    <div className={styles.stepsCircle} style={{
      background: backgroundColour,
    }}>
      <div className={addContainer ? 'container' : ''} style={{
        ...(!addPadding ? {
          paddingTop: 0,
          paddingBottom: 0
        } : {})
      }}>
        <div className={styles.stepsCircleLeft}>
          {stepsData.slice(stepsData.length / 2).map((step, stepIdx) => (
            <div 
              key={stepIdx}
              className={styles.stepsCircleStepItemWrapper} 
              onMouseEnter={() => setHoveredStep((stepsData.length / 2) + stepIdx + 1)} 
              onMouseLeave={() => setHoveredStep(0)}
              onClick={() => setHoveredStep((stepsData.length / 2) + stepIdx + 1)}
              style={{
                opacity: hoveredCircle > 0 ? (hoveredCircle === ((stepsData.length / 2) + stepIdx + 1) ? 1 : 0.4) : 1
              }}
            >
              <CircleStepItem 
                key={stepIdx}
                title={`${(stepsData.length / 2) + stepIdx + 1}. ${step.title}`}
                content={step.value}
                link={step.link}
                hoverContent
                isHovered={hoveredCircle === ((stepsData.length / 2) + stepIdx + 1) || hoveredStep === ((stepsData.length / 2) + stepIdx + 1)}
              />
            </div>
          ))}
        </div>
        <div className={styles.stepsCircleCentre}>
          <div className={styles.stepsCircleCentreContainer}>
            <div className={styles.stepsCircleBackground} />
            <div className={styles.stepsCircleContent}>
              <div className={styles.stepsCircleLogo}>
                <Image src={logo} alt="Breakeven" />
              </div>
              <div className={styles.stepsCircleText}>Reducing the impact of gambling related harms through client focused treatment, awareness raising and education</div>
            </div>
            {stepsData.map((step, stepIdx) => (
              <CircleItem 
                key={stepIdx}
                icon={step.icon}
                top={step.top}
                left={step.left}
                bottom={step.bottom}
                right={step.right}
                backgroundColour={backgroundColour}
                onHover={isHovered => setHoveredCircle(isHovered ? stepIdx + 1 : 0)}
                isHovered={hoveredStep > 0 && hoveredStep === (stepIdx + 1)}
              />
            ))}
          </div>
        </div>
        <div className={styles.stepsCircleRight}>
          {stepsData.map((step, stepIdx) => (
            <div 
              key={stepIdx}
              className={[styles.stepsCircleStepItemWrapper, stepIdx >= (stepsData.length / 2) ? styles.stepsCircleStepItemWrapperMobile : ''].join(' ')} 
              onMouseEnter={() => setHoveredStep(stepIdx + 1)} 
              onMouseLeave={() => setHoveredStep(0)}
              onClick={() => setHoveredStep(stepIdx + 1)} 
              style={{
                opacity: hoveredCircle > 0 ? (hoveredCircle === (stepIdx + 1) ? 1 : 0.4) : 1
              }}
            >
              <CircleStepItem 
                title={`${stepIdx + 1}. ${step.title}`}
                content={step.value}
                link={step.link}
                hoverContent
                isHovered={hoveredCircle === (stepIdx + 1) || hoveredStep === (stepIdx + 1)}
              />
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

const CircleItem = ({
  icon,
  top, 
  left,
  bottom,
  right,
  backgroundColour,
  onHover,
  isHovered
}: {
  icon: IconProp
  top?: string | number
  left?: string | number
  bottom?: string | number
  right?: string | number
  backgroundColour: string
  onHover?: (isHovered: boolean) => void
  isHovered?: boolean
}) => {
  return (
    <div className={[styles.stepsCircleItem, isHovered ? styles.stepsCircleItemHover : ''].join(' ')} style={{
      top,
      left,
      right,
      bottom,
      background: backgroundColour
    }} onMouseEnter={() => onHover?.(true)} onMouseLeave={() => onHover?.(false)} onClick={() => onHover?.(true)}>
      <FontAwesomeIcon icon={icon} />
    </div>
  )
}

export const CircleStepItem = ({
  content,
  title,
  icon,
  link,
  style,
  hoverContent,
  isHovered,
  canOverflow
}: {
  content?: ReactNode
  title?: string
  icon?: IconProp
  link?: string
  style?: CSSProperties
  hoverContent?: boolean
  isHovered?: boolean
  canOverflow?: boolean
}) => {
  return (
    <div className={[styles.stepsCircleStepItem, (hoverContent ? isHovered : true) ? styles.stepsCircleStepItemHover : ''].join(' ')} style={style}>
      {icon &&
        <div className={styles.stepsCircleStepItemIcon}>
          <FontAwesomeIcon icon={icon} size="1x" color="#D11A6D" />
        </div>
      }
      <div style={{ width: '100%' }}>
        {title &&
          <div className={styles.stepsCircleStepItemTitle}>
            <div style={canOverflow ? {} : {
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
              overflow: 'hidden'
            }}>{title}</div>
            {hoverContent &&
              <FontAwesomeIcon icon={faChevronRight} />
            }
          </div>
        }
        {content &&
          <AnimatePresence initial={false}>
            {(hoverContent ? isHovered : true) &&
              <motion.div 
                initial="collapsed"
                animate="open"
                exit="collapsed"
                variants={{
                  open: {
                    height: 'auto'
                  },
                  collapsed: {
                    height: 0
                  }
                }}
                transition={{
                  duration: 0.2
                }}
                style={{
                  overflow: 'hidden'
                }}
              >
                <div 
                  className={styles.stepsCircleStepItemContent} 
                  style={{
                    marginBottom: link ? 20 : 0
                  }}
                  
                >{content}</div>
                {link &&
                  <div className={styles.stepsCircleStepItemLink}>
                    <Link href={link}>
                      <a>Learn more<FontAwesomeIcon icon={faArrowRight} /></a>
                    </Link>
                  </div>
                }
              </motion.div>
            }
          </AnimatePresence>
        }
      </div>
    </div>
  )
}