import React, { ReactNode } from 'react'

import styles from './ItemSection.module.scss'

const ItemSection = ({
  title,
  titleColour,
  description,
  items = [],
  backgroundColour,
  itemBackgroundColour,
  gutterHorizontal,
  gutterVertical,
  size = 4,
  addContainer = true,
  leftAlign,
  itemsTopPadding,
  noPadding
}: {
  title?: string
  titleColour?: string
  description?: string
  items?: Array<ReactNode>
  backgroundColour?: string
  itemBackgroundColour?: string
  gutterHorizontal?: number
  gutterVertical?: number
  size?: number
  addContainer?: boolean
  leftAlign?: boolean
  itemsTopPadding?: number
  noPadding?: boolean
}) => {
  return (
    <div className={[styles.itemSection, styles[`size-${size}`]].join(' ')} style={{
      background: backgroundColour || '#F1F1F1',
      ...(noPadding ? {
        paddingTop: 0,
        paddingBottom: 0
      } : {})
    }}>
      <div className={`${styles.itemSectionContainer || ''}${addContainer ? ' container' : ''}`}>
        {title &&
          <h3 className={styles.itemSectionTitle} style={{
            color: titleColour || '#D11A6D'
          }}>{title}</h3>
        }
        {description &&
          <h2 className={styles.itemSectionDescription}>{description}</h2>
        }
        {(items?.length ?? 0) > 0 &&
          <div className={`${styles.itemSectionItems} ${leftAlign ? styles.itemSectionItemsLeft : ''}`} style={{
            marginLeft: -(gutterHorizontal ? (gutterHorizontal / 2) : 30),
            marginRight: -(gutterHorizontal ? (gutterHorizontal / 2) : 30),
            marginTop: -(gutterVertical ? (gutterVertical / 2) : 30),
            marginBottom: -(gutterVertical ? (gutterVertical / 2) : 30),
            paddingTop: itemsTopPadding ?? 0
          }}>
            {items.map((x, idx) => (
              <div key={idx} className={styles.itemSectionItem} style={{
                paddingLeft: (gutterHorizontal / 2) || 30,
                paddingRight: (gutterHorizontal / 2) || 30,
                paddingTop: (gutterVertical / 2) || 30,
                paddingBottom: (gutterVertical / 2) || 30
              }}>
                {x}
              </div>
            ))}
          </div>
        }
      </div>
    </div>
  )
}

export default ItemSection